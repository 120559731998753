/*      About     */
.about {
    display: flex;
    margin: auto;
    gap: 2rem;
}

.about .skills {
    width: 70%;
    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
}


.about .me {
    padding-bottom: 1rem;
}

.about .me p {
    color: var(--clr-black);
    text-align: justify;
}

.about .me a {
    color: var(--primary-clr);
    font-weight: 600;
    padding: .5rem;
    text-decoration: none;
}

.about .me a:hover {
    color: var(--clr-black);
}

.about p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #646f87;
}

.about-skills {
    /* margin: 0 1rem; */
    /* flex-basis: 45%; */
    /* padding-bottom: 15px; */
    padding-top: 10px;
}

.skill-item {
    margin-top: 20px;
}

.skill-header {
    position: relative;
}

.skill-header h6 {
    gap: .5rem;
    display: flex;
    font-size: 16px;
    font-weight: 500;
}

.skill-header p {
    position: absolute;
    top: -3px;
    font-size: 14px;
    right: 0;
}

.skill-bar {
    margin-top: 15px;
    width: 100%;
    height: 9px;
    border-radius: 5px;
    background-color: rgba(100, 111, 135, .11);
    position: relative;
}

.progress-line {
    position: absolute;
    height: 9px;
    border-radius: 5px;
    background-color: var(--primary-clr);
    transition: all 2s ease-out 0s;
}

.tag {
    margin-right: 0.25rem;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 0.375rem;
    border-style: none;
    padding: 0.25rem 0.5rem 0.375rem;
    font-size: .90rem;
    line-height: 1rem;
    --tw-bg-opacity: .3;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(178 190 205 / var(--tw-text-opacity));
  }


@media screen and (max-width:750px) {

    .social {
        display: none !important;
    }

    nav {
        display: none;
        background-color: var(--primary-clr);
    }

    .navbar {
        display: block;
        padding: 10px;
    }

    .navbar ul {
        padding-top: 1rem;
        display: block;
    }

    .navbar ul a {
        padding: 1rem 0;
        display: inline-block;
    }

    .navbar button {
        display: unset;
    }

    .container {
        width: 80%;
    }

    .home-content input {
        padding: 14px 24px;
    }

    .home-content input:hover {
        padding: 14px 24px;
        font-size: 16px;
    }

    .home .container {
        padding-top: 35px;
        flex-direction: column;
    }

    .home .container>div {
        width: unset;
        height: 50%;
    }

    .home .home-image {
        text-align: center;
    }

    .home .home-image img {
        height: 40vh;
        max-height: unset;
        position: relative;
        right: -40px;
    }

    .home .back::before {
        width: 320%;
        transform: skewX(310deg);
        top: 76px;
    }

    .about-skills {
        /* flex-basis: 100%; */
    }

    .footDev {
        display: grid !important;
        gap: 0.1rem !important;
        font-size: 14px;
    }

    .footDev #devStyle {
        display: none;
    }

}