@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800|Open+Sans:400,600,700&display=swap);
:root {
  --clr-white: #fdfdfd;
  --clr-black: #233149;
  --primary-clr: #1f5ca7;
  --mw-14: 1140px;
  --mw-96: 960px
}

* {
  padding: 0;
  margin: 0;
  font-family: 'montserrat';
}

/*      BODY    */
body {
  margin: 0;
  font-family: open sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfdfd;
  background-color: var(--clr-white);
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #233149;
  background-color: var(--clr-black);
}

/*  SHARED  */

.container {
  margin: auto;
  width: 60%;
  max-width: 1140px;
  max-width: var(--mw-14);
  display: flex;
  position: relative;
}


.info h3 {
  margin-top: 10px;
  color: #1f5ca7;
  color: var(--primary-clr);
  font-size: 20px;
  font-weight: 600;
}

.info h4 {
  color: #233149;
  color: var(--clr-black);
  font-size: 26px;
  font-weight: 600;
}

.line li {
  display: inline-block;
  background-color: #1f5ca7;
  background-color: var(--primary-clr);
  border-radius: 50px;
  height: 3px;
}

.line li:nth-last-of-type(3) {
  width: 64px;
}

.line li:nth-last-of-type(2) {
  width: 10px;
  margin: 0 4px;
}

.line li:nth-last-of-type(1) {
  width: 7px;
}
.not-finished{
  flex-direction: column;
  align-items: center;
}

.not-finished a {
  color: #1f5ca7;
  color: var(--primary-clr);
  font-weight: 600;
  padding: 0.5rem;
}

.not-finished a:hover {
  color: #233149;
  color: var(--clr-black);
}

.certificate-item{
  background-color: whitesmoke;
  text-decoration: none;
  color: #233149;
  color: var(--clr-black);
  padding: 1rem;
  border-radius: 7px;
  display: block;
}
.certificate-img{
  width: 100%;
  border-radius: 5px;
}

/* Scroll Animation */
.hidden{
  opacity: 0;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 1s;
}
.show{
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@media(prefers-reduced-motion){
  .hidden{
    transition: none;
  }
}

.hidden:nth-child(2){
  transition-delay: 200ms;
}

/*      NAVBAR     */
nav {
    z-index: 1;
    position: absolute;
    width: 100%;
}

.navbar {
    padding: 25px 0;
    max-width: var(--mw-14);
    display: flex;
    justify-content: space-around;
    margin: auto;
    font-weight: 600;
    width: 90%;
}

.navbar button {
    display: none;
}

nav .logo {
    font-size: 1.5rem;
    text-decoration: none;
    color: var(--primary-clr);
    display: flex;
    align-items: center;
}

nav .logo img {
    transition: .3s;
    height: 26px;
}

nav .logo img:hover {
    height: 30px;
}

nav ul {

    display: flex;
    list-style: none;
}

nav ul a {
    padding: 0 1rem;
    text-decoration: none;
    color: var(--clr-white);
    transition: .3s;
}

.nav-link{
    cursor: pointer;
}

.navbar-fixed ul a {
    color: var(--primary-clr);
}

nav ul a:hover {
    font-size: 18px;
    padding: 0 1.5rem;
    color: var(--clr-white);
}

.navbar-fixed{
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 0 20px -5px var(--primary-clr);
    color: var(--primary-clr);
}

.navbar-fixed ul a:hover {
    color: var(--primary-clr);
}
/*      HOME CONTAINER     */

.home {
    z-index: 2;
    background: whitesmoke;
    overflow: hidden;
    position: relative;
    font-family: montserrat, sans-serif;
}

.home .back {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.home .back::before {
    position: absolute;
    content: '';
    width: 150%;
    height: 100%;
    background-color: var(--primary-clr);
    -webkit-transform: skewX(20deg);
            transform: skewX(20deg);
    top: 0;
    left: 0;
}

.home .container{
    padding-top: 120px;
}

.home .container>div {
    width: 50%;
}

.home-image img {
    height: 600px;
    top: 4px;
    position: relative;
}

.home .social {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 30%;
    left: 20px;
    background: whitesmoke;
    width: 41px;
    text-align: center;
    padding: .5rem 0;
    border-radius: 50px;
    font-size: 1.3rem;
    border: 1px solid var(--primary-clr);
}

.home .social a {
    text-decoration: none;
    color: var(--primary-clr);
    transition: .3s;
    padding: .5rem 0;
}

.home .social a:hover {
    color: var(--clr-black);
    font-size: 1.7rem;
}

.home .home-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-content .content {
    font-size: 2rem;
    display: block;
}

.home-content h4 {
    font-weight: 600;
    color: var(--primary-clr);
}

.home-content h2 {
    padding-top: 20px;
    color: var(--clr-black);
}

.home-content span {
    font-size: 18px;
    color: var(--clr-black);
    margin-top: 15px;
}

.home-content input {
    cursor: pointer;
    font-family: montserrat, sans-serif;
    margin-top: 30px;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 5px;
    border: 0;
    background-color: var(--primary-clr);
    color: var(--clr-white);
    font-size: 16px;
    transition: .3s;
}

.home-content input:hover {
    background-color: var(--clr-black);
    padding: 20px 35px;
    font-size: 19px;
}

.span_content {
    font-size: medium;
    font-weight: normal;
    margin-top: 1rem;
}

.social-link .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: medium;
    left: 48px;
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.social-link:hover .tooltiptext {
    visibility: visible;
}
/*      About     */
.about {
    display: flex;
    margin: auto;
    grid-gap: 2rem;
    gap: 2rem;
}

.about .skills {
    width: 70%;
    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
}


.about .me {
    padding-bottom: 1rem;
}

.about .me p {
    color: var(--clr-black);
    text-align: justify;
}

.about .me a {
    color: var(--primary-clr);
    font-weight: 600;
    padding: .5rem;
    text-decoration: none;
}

.about .me a:hover {
    color: var(--clr-black);
}

.about p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #646f87;
}

.about-skills {
    /* margin: 0 1rem; */
    /* flex-basis: 45%; */
    /* padding-bottom: 15px; */
    padding-top: 10px;
}

.skill-item {
    margin-top: 20px;
}

.skill-header {
    position: relative;
}

.skill-header h6 {
    grid-gap: .5rem;
    gap: .5rem;
    display: flex;
    font-size: 16px;
    font-weight: 500;
}

.skill-header p {
    position: absolute;
    top: -3px;
    font-size: 14px;
    right: 0;
}

.skill-bar {
    margin-top: 15px;
    width: 100%;
    height: 9px;
    border-radius: 5px;
    background-color: rgba(100, 111, 135, .11);
    position: relative;
}

.progress-line {
    position: absolute;
    height: 9px;
    border-radius: 5px;
    background-color: var(--primary-clr);
    transition: all 2s ease-out 0s;
}

.tag {
    margin-right: 0.25rem;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    border-radius: 0.375rem;
    border-style: none;
    padding: 0.25rem 0.5rem 0.375rem;
    font-size: .90rem;
    line-height: 1rem;
    --tw-bg-opacity: .3;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(178 190 205 / var(--tw-text-opacity));
  }


@media screen and (max-width:750px) {

    .social {
        display: none !important;
    }

    nav {
        display: none;
        background-color: var(--primary-clr);
    }

    .navbar {
        display: block;
        padding: 10px;
    }

    .navbar ul {
        padding-top: 1rem;
        display: block;
    }

    .navbar ul a {
        padding: 1rem 0;
        display: inline-block;
    }

    .navbar button {
        display: unset;
    }

    .container {
        width: 80%;
    }

    .home-content input {
        padding: 14px 24px;
    }

    .home-content input:hover {
        padding: 14px 24px;
        font-size: 16px;
    }

    .home .container {
        padding-top: 35px;
        flex-direction: column;
    }

    .home .container>div {
        width: unset;
        height: 50%;
    }

    .home .home-image {
        text-align: center;
    }

    .home .home-image img {
        height: 40vh;
        max-height: unset;
        position: relative;
        right: -40px;
    }

    .home .back::before {
        width: 320%;
        -webkit-transform: skewX(310deg);
                transform: skewX(310deg);
        top: 76px;
    }

    .about-skills {
        /* flex-basis: 100%; */
    }

    .footDev {
        display: grid !important;
        grid-gap: 0.1rem !important;
        gap: 0.1rem !important;
        font-size: 14px;
    }

    .footDev #devStyle {
        display: none;
    }

}
/* EXPERIENCE */

.experiences {
    padding: .5rem 1rem;
}

.experience{
    display: flex;
    margin-top: 15px;
}

.experience .description{
    border-left: #6a8285 solid;
}

.experience-logo {
    width: 60px;
    height: 60px;
    border-radius: 7px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.experience-pro h4 {
    font-size: 17px;
    display: flex;
    flex-direction: column;
}

.experience-pro .company {
    font-size: 15px;
    margin-top: .5rem;
    color: var(--primary-clr);
    font-weight: bold;
    text-decoration: none;
}

.experience-pro .company:hover {
    color: var(--clr-black);
}

.experience-pro .date {
    font-size: 15px;
    font-weight: normal;
    color: #6a8285;
}

.experience-pro p {
    margin-top: .5rem;
}


/* PROJECTS */

.projects {
    display: flex;
    padding: 2rem 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.project {
    padding: 1rem;
    border: 1px solid var(--primary-clr);
    border-radius: 7px;
}
footer {
    padding: 1.5rem;
    position: static;
    bottom: 0;
    text-align: center;
    background-color: #151c32;
    color: whitesmoke;
}


footer h2 {
    color: #00aeef;
}


.contact_frm {
    width: 90%;
    max-width: 1170px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.contact_frm svg {
    width: 50px;
}

.contact_frm table {
    display: grid;
    justify-content: center;
    grid-gap: 7px;
    gap: 7px;
    margin: 7px 0;
}

.contact_frm form {
    margin-top: 1rem;
}

.contact_frm input[type=text],
.contact_frm input[type=email],
.contact_frm textarea {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    width: 90%;
    color: #222;
    font-size: 1rem;
}

.btn_send {
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    width: 60%;
    background-color: #00aeef;
    color: #fff;
    max-width: 200px;
    cursor: pointer;
    border: 0;
}

.btn_send:hover {
    color: #000;
}

.btn_valid {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: 60%;
    background-color: #00ef55;
    color: var(--clr-black);
    max-width: 200px;
}

.btn_sending {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: 60%;
    background-color: #b3bec2;
    max-width: 200px;
}


.footline {
    height: 2px;
    width: 90%;
    background-color: #00aeef;
    margin: 1.5rem auto;
    max-width: 1170px;
}

.footDev p {
    color: whitesmoke;
    margin: 0;
}

.footDev {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}
