/* EXPERIENCE */

.experiences {
    padding: .5rem 1rem;
}

.experience{
    display: flex;
    margin-top: 15px;
}

.experience .description{
    border-left: #6a8285 solid;
}

.experience-logo {
    width: 60px;
    height: 60px;
    border-radius: 7px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.experience-pro h4 {
    font-size: 17px;
    display: flex;
    flex-direction: column;
}

.experience-pro .company {
    font-size: 15px;
    margin-top: .5rem;
    color: var(--primary-clr);
    font-weight: bold;
    text-decoration: none;
}

.experience-pro .company:hover {
    color: var(--clr-black);
}

.experience-pro .date {
    font-size: 15px;
    font-weight: normal;
    color: #6a8285;
}

.experience-pro p {
    margin-top: .5rem;
}


/* PROJECTS */

.projects {
    display: flex;
    padding: 2rem 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.project {
    padding: 1rem;
    border: 1px solid var(--primary-clr);
    border-radius: 7px;
}